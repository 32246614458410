import { defineStore } from "pinia";
export const useOpenAudioNotification = defineStore("AudioNotification", {

  state: () => {
    return {
      OpenAudioNotification: false
    }
  },

  actions: {
    open(): void {
      console.log('Teste chamada');
      this.OpenAudioNotification = true
    },
    close(): void {
      this.OpenAudioNotification = false
    }
  },

  getters: {
    status(): boolean {
      return this.OpenAudioNotification
    }
  }
});






